import React from 'react';
import { Heading,Box,Text,SimpleGrid,Flex,Spinner,useColorMode} from "@chakra-ui/react"
import {Link} from 'gatsby'
import {motion} from 'framer-motion'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import MeinSeo from '../components/seo'
import { graphql } from 'gatsby';
      
const Dialog = ( {bild,text,link,alt, starttitel}) => {
  const colorMode = useColorMode()
  return(
    <Box position="relative" my="2" px="3" bg={colorMode.colorMode==="light"?"whiteAlpha.900":"gray.800"} >
 <Heading variant="starttitel" textAlign="left">{starttitel} </Heading>
    <Flex flexDir={["column","row","row","row"] }>
      <Box  flex="1"  textAlign="center" >
      <Box >
      <GatsbyImage image={bild} alt={alt}  />
      </Box>
       <Text variant='SBild'> </Text>
      </Box>
      <Box flex={[1,1]} textAlign="center" >
      <Text textAlign="left" variant='start'> {text} </Text>
      </Box>
    </Flex>
    <Box position="absolute" top="98%">
    <Link to={link}> <Text pl="3" variant='startlinks'>&rarr; mehr ...</Text> </Link>
    </Box>
    </Box>
  )

}



const Prolog = ({data}) => {

 if(data.startbilder)
  return (
     <motion.div
    initial={{opacity:0,scale:.3}}
    animate={{opacity:1,scale:1}}
    transition={{duration:.3,delay:.1,ease:'easeOut'}}>
    <MeinSeo title="Ausstellungsparcours zum Jüdischen Leben im Hunsrück " 
    description="Das K.O.M veranstaltet im Zusammenhang mit dem Theaterfestival 'An den Ufern der Poesie' den Jüdischen Ausstellungsparcours mit dem Hunsrück Museum in Simmern u.a. "
    />
<Box height="100%">
 

 
 <Box pb="100px" pt="10px" display="flex" justifyContent="center">

    <SimpleGrid  columns={[1,1,2,2]} gap="4" maxWidth="1200">
       <Dialog 
       bild={data.startbilder.edges[0].node.childImageSharp.gatsbyImageData}
       text= 'Seit 1700 Jahren leben Jüdinnen und Juden nachweislich auf dem Gebiet des heutigen Deutschland.  Mit den römischen Legionen waren auch Menschen jüdischer Religion ins Rheinland gekommen, möglicherweise als Kaufleute, Winzer, Weinhändler, Soldaten oder Ärzte.' 
       starttitel="Landjudentum im Hunsrück"
       link="/beitrag-1"
       alt="Jüdische Viehhändler"
       
       />
       <Dialog 
       bild={data.startbilder.edges[1].node.childImageSharp.gatsbyImageData}
       text= 'Über längere Zeit versäumte die deutsche Geschichtswissenschaft die Erforschung der jüdischen Lokalgeschichte. Das traf auch auf die Gemeinden am Mittelrhein und im Hunsrück zu.' 
       starttitel="Jüdische Schicksale am Mittelrhein "
       link="/beitrag-2"
       alt=""
       />
       <Dialog 
       bild={data.startbilder.edges[2].node.childImageSharp.gatsbyImageData}
       text= 'Die national-sozialistische Gewaltherrschaft hatte zum Ziel, die jüdische Kultur mitsamt ihren Gläubigen zu vernichten. Dazu gehörten auch die religiösen Zentren.'
       starttitel="Virtuelle Rekonstruktion von Synagogen "
       link="/beitrag-3"
       alt="Virtuelle Rekonstruktion "
       />
        <Dialog 
       bild={data.startbilder.edges[3].node.childImageSharp.gatsbyImageData}
       text= ' In 48 teils hochwertigen Exponaten wird der in Oberwesel bis 1971 bestehende antijüdische Kult um das angebliche Ritualmordopfer Werner von Oberwesel kontextualisiert. Dazu zählen Altarbilder, Statuen, Reliquiare, Bücher und Dokumente. '
       starttitel="Der Wernerkult"
       link="/beitrag-4"
       alt="Wernerbild"
       />
      <Dialog 
       bild={data.startbilder.edges[4].node.childImageSharp.gatsbyImageData}
       text= 'Nur wenige Spuren bezeugen heute den Verlust der jüdischen Gemeinden und ihrer Mitglieder. Vor allem den jüngeren gelang die Flucht, die meisten älteren wurden deportiert, in den Konzentrationslagern ermordet oder blieben verschollen. '
       starttitel="Menschen, Stimmen, Erinnerungen"
       link="/beitrag-5"
       alt="Ausreisedokument"
       />
       <Dialog 
       bild={data.startbilder.edges[5].node.childImageSharp.gatsbyImageData}
       text= 'Fragmente des "Roten Fensters", einer von dem Künstler Karl-Martin Hartmann (geb. 1948) geschaffenen und von 2007 bis 2010 in der Bacharacher Wernerkapelle ausgestellten und weithin sichtbaren Installation, stellen ...'
       starttitel="Bruchstücke des roten Fensters"
       linktext ="Rotes Fenster"
       link="/beitrag-7"
       alt="Rotes Fenster"
       />
       <Dialog 
       bild={data.startbilder.edges[6].node.childImageSharp.gatsbyImageData}
       text= 'Der Maler Ferdinand Friess (geb. 1940) hat sich mit seinen großformatigen Bildern und Installationen dem Engagement gegen Geschichtsvergessenheit verschrieben.'
       starttitel=" Gemälde von Ferdinand Friess"
       linktext ="Bilder von Ferdinand Friess"
       link="/beitrag-6"
       alt="Teddy von Ferdinand Friess"
       />
       <Dialog 
       bild={data.startbilder.edges[7].node.childImageSharp.gatsbyImageData}
       text= 'Das Schaufenster gewährt einen Blick auf diesen ganz besonderen Feiertag: Kein anderer Tag spielt im Leben einer jüdischen Familie eine ähnlich große Rolle wie der Schabbat.'
       starttitel="Schabbes"
       linktext ="Schabbes"
       link="/beitrag-8"
       alt="Schabbes"
       />
       </SimpleGrid>
       
       </Box>
       <Box pl="0">
       <Box bg="white" >
           
           <Text pl="3"  pt="3" m="0" color="gray.900" fontWeight="800"  fontSize="xs">Veranstalter:</Text>
        <Box>
        <StaticImage src="../images/log/icon.png" height={120} alt=""/>
        </Box>
         <Box bg="white" display="flex">
         
           
        </Box>
           <Box width="40%"  py="3">
             <Text p="3" color="gray.900" fontWeight="800" fontSize="xs">gefördert durch:</Text>
           </Box>
          
       
        
         </Box>
      
         <Flex bg="white" flexWrap="wrap">
       
         <Box bg="white" p="3"><StaticImage src="../images/log/Beauftragter.jpg" height={50} alt=""/></Box>
         <Box bg="white" p="3"><StaticImage src="../images/log/Logo-AAS.jpg" height={50} alt=""/></Box>
         
         </Flex>
         <Box bg="white" p="3"><Text color="gray.900" fontWeight="800" fontSize="xs">Kooperationspartner:</Text></Box>
        
        <Flex bg="white" flexWrap="wrap">
         <Box p="3"><StaticImage src="../images/log/Logo_festival.jpg" height={80} alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/stadtmuseum.jpg" height={40} alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/Zweckverband.jpg" height={80} alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/vierthaeler.jpg" height={40}  alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/laufersweiler.jpg" height={60}  alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/Kirche.jpg" height={40} width={160}  alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/Oberwesel.jpg" height={80}   alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/Bacharach.jpg" height={90}   alt=""/></Box>
         <Box p="3"><StaticImage src="../images/log/Initial-B-2.jpg" height={100}  alt=""/></Box>
        
         </Flex>
     
         
       </Box>
  
       </Box>
       </motion.div>
  )
  return (<Box><Spinner/></Box>)
}
export const query = graphql`

{

  startbilder: allFile(
    filter: { relativeDirectory: { eq: "start" } }
    sort: { order: ASC, fields: base }
  ) {
    edges {

      node {

       base

        id
       
        relativePath

        childImageSharp {
           gatsbyImageData(
            layout: CONSTRAINED
            placeholder: BLURRED
            height: 600
            transformOptions: {
              cropFocus: ATTENTION
              fit: COVER
              
            }
           
            
          )
        }
        base
      }

    }

  }
 
}`

export default Prolog
